<template>
  <div>
    <breadcrumb param1="电影" param2="电影分类" />
    <el-card>
      <el-alert title="这些分类会上传的电影关联的、添加并跟电影关联后尽量别删除！" type="warning"  show-icon>
  </el-alert>
      <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top:15px;">
        <el-tab-pane label="分类" name="1">
          <el-button type="warning"  icon="el-icon-plus" @click="addDialogVisible = true" size="small">添加分类</el-button>
          <el-table :data="cateList" style="width: 100%" v-loading="loading">
            <el-table-column type="index" label="#" width="50"> </el-table-column> 
            <el-table-column label="名称"> 
              <template slot-scope="scope">
                <span class="uy-text">{{scope.row.movie_cate_title}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="movie_cate_order" label="排序" width="100"> </el-table-column>
            <el-table-column prop="movie_cate_update_time" label="最后更新" width="180"> </el-table-column>
            <el-table-column label="操作" width="260">
              <template slot-scope="scope">
                <el-row>
                  <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
                  <el-button type="danger" icon="el-icon-delete" size="mini"  @click="deleteCate(scope.row.id)">删除</el-button>
                </el-row>
              </template>
             </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="类型" name="2">
          <el-button type="warning"  icon="el-icon-plus" @click="addDialogVisible = true" size="small">添加类型</el-button>
           <el-table :data="cateList" style="width: 100%" v-loading="loading">
            <el-table-column label="名称"> 
                <template slot-scope="scope">
                <span class="uy-text">{{scope.row.movie_cate_title}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="movie_cate_order" label="排序" width="100"> </el-table-column>
            <el-table-column prop="movie_cate_update_time" label="最后更新" width="180"> </el-table-column>
            <el-table-column label="操作" width="260">
              <template slot-scope="scope">
               <el-row>
                  <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
                  <el-button type="danger" icon="el-icon-delete" size="mini"  @click="deleteCate(scope.row.id)">删除</el-button>
                </el-row>
              </template>
             </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="语言" name="3">
          <el-button type="warning"  icon="el-icon-plus" @click="addDialogVisible = true" size="small">添加语言</el-button>
          <el-table :data="cateList" style="width: 100%" v-loading="loading">
            <el-table-column label="名称"> 
                <template slot-scope="scope">
                <span class="uy-text">{{scope.row.movie_cate_title}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="movie_cate_order" label="排序" width="100"> </el-table-column>
            <el-table-column prop="movie_cate_update_time" label="最后更新" width="180"> </el-table-column>
            <el-table-column label="操作" width="260">
              <template slot-scope="scope">
                 <el-row>
                  <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
                  <el-button type="danger" icon="el-icon-delete" size="mini"  @click="deleteCate(scope.row.id)">删除</el-button>
                </el-row>
              </template>
             </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="地区" name="4">
          <el-button type="warning"  icon="el-icon-plus" @click="addDialogVisible = true" size="small">添加地区</el-button>
          <el-table :data="cateList" style="width: 100%" v-loading="loading">
            <el-table-column label="名称"> 
                <template slot-scope="scope">
                <span class="uy-text">{{scope.row.movie_cate_title}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="movie_cate_order" label="排序" width="100"> </el-table-column>
            <el-table-column prop="movie_cate_update_time" label="最后更新" width="180"> </el-table-column>
            <el-table-column label="操作" width="260">
              <template slot-scope="scope">
                 <el-row>
                  <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
                  <el-button type="danger" icon="el-icon-delete" size="mini"  @click="deleteCate(scope.row.id)">删除</el-button>
                </el-row>
              </template>
             </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="年份" name="5">
          <el-button type="warning"  icon="el-icon-plus" @click="addDialogVisible = true" size="small">添加年份</el-button>
          <el-table :data="cateList" style="width: 100%" v-loading="loading">
            <el-table-column label="名称"> 
                <template slot-scope="scope">
                <span class="uy-text">{{scope.row.movie_cate_title}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="movie_cate_order" label="排序" width="100"> </el-table-column>
            <el-table-column prop="movie_cate_update_time" label="最后更新" width="180"> </el-table-column>
            <el-table-column label="操作" width="260">
              <template slot-scope="scope">
                 <el-row>
                  <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
                  <el-button type="danger" icon="el-icon-delete" size="mini"  @click="deleteCate(scope.row.id)">删除</el-button>
                </el-row>
              </template>
             </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!-- 添加分类对话框 -->
    <el-dialog :title="'添加' + titleText" :visible.sync="addDialogVisible" width="30%">
      <el-form ref="addFormRef" :model="addCateForm" :rules="addCateFormRules" label-width="80px">
        <el-form-item :label="titleText + '名称'" prop="movie_cate_title">
          <el-input v-model="addCateForm.movie_cate_title"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="movie_cate_order">
          <el-input v-model="addCateForm.movie_cate_order"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCate">确 定</el-button>
      </span>
    </el-dialog>
       <!-- 编辑分类对话框 -->
    <el-dialog :title="'编辑' + titleText" :visible.sync="editDialogVisible" width="30%">
      <el-form ref="editFormRef" :model="editCateForm" :rules="editCateFormRules" label-width="80px">
        <el-form-item :label="titleText + '名称'" prop="movie_cate_title">
          <el-input v-model="editCateForm.movie_cate_title"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="movie_cate_order">
          <el-input v-model="editCateForm.movie_cate_order"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editCate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
   data() {
      return {
        activeName: '1',
        addDialogVisible: false,
        editDialogVisible: false,
        loading:true,
        cateList:[],
        addCateForm:{
          movie_cate_title:'',
          movie_cate_order:'0'
        },
        editCateForm:{},
        addCateFormRules:{
          movie_cate_title:[
            { required:'true', message:'请输入名称', trigger:'blur' }
          ],
          movie_cate_order:[
            { required:'true', message:'请输入排序号', trigger:'blur' }
          ]
        },
        editCateFormRules:{
          movie_cate_title:[
            { required:'true', message:'请输入名称', trigger:'blur' }
          ],
          movie_cate_order:[
            { required:'true', message:'请输入排序号', trigger:'blur' }
          ]
        }
      };
    },
    created(){
      this.getCateList()
    },
    methods: {
      handleClick(tab, event) {
        this.getCateList()
        console.log(this.titleText)
      },
      async getCateList(){
        const {data : res} = await this.$http.get('movie_cate/' + this.activeName)
        if(res.code !== 200){
          this.loading = false
          return this.$message.error('获取失败')
        }
        this.cateList = res.data
        this.loading = false
      },
      addCate(){
        this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const { data: res } = await this.$http.post("movie_cate/" + this.activeName,this.addCateForm);
        if (res.code !== 200) {
          return this.$message.error("添加失败");
        }
        this.getCateList()
        this.$message.success("添加成功");
        this.addDialogVisible = false;
        });
      },
      async showEditDialog(id){
        const {data : res} = await this.$http.get('movie_cate/cate/' + id)
        if(res.code !== 200){
          return this.$message.error(res.msg)
        }
        this.editCateForm = res.data
        console.log(res)
        this.editDialogVisible = true
      },
      editCate(){
        this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const { data: res } = await this.$http.put("movie_cate/" + this.activeName,this.editCateForm);
        if (res.code !== 201) {
          return this.$message.error(res.msg);
        }
        this.getCateList()
        this.$message.success(res.msg);
        this.editDialogVisible = false;
        });
      },
      async deleteCate(id) {
        const confirmRes = await this.$confirm(
          "此操作将永久删除该分类, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).catch(err => err);

        if (confirmRes !== "confirm") {
          return this.$message.info("取消删除");
        }

        const { data: res } = await this.$http.delete("movie_cate/" + id);
        if (res.code !== 201) {
          return this.$message.error("删除失败");
        }
        this.$message.success("删除成功");
        this.getCateList();
      }
    },
    computed:{
      titleText(){
        if(this.activeName === '1'){
          return '分类'
        }else if(this.activeName === '2'){
          return '类型'
        }else if(this.activeName === '3'){
          return '语言'
        }else if(this.activeName === '4'){
          return '地区'
        }
        return '年份'
      }
    }
}
</script>