<template>
  <div>
    <breadcrumb param1="小程序" param2="参数配置" />
    <el-card>
        <el-row>
        <el-col :span="12">
          <el-alert title="请填写正确的参数值、若填写错误会出现无法登录或者无法正常支付等一些问题!" type="warning" style="margin-bottom:15px;"></el-alert>
          <el-form ref="paramFormRef" :model="paramForm"  :rules="paramFormRules" label-width="120px">
            <el-form-item label="小程序名称" prop="appname">
              <el-input v-model="paramForm.appname"></el-input>
            </el-form-item>
            <el-form-item label="APPID" prop="appid">
              <el-input v-model="paramForm.appid"></el-input>
            </el-form-item>
            <el-form-item label="APPSECRET" prop="appsecret">
              <el-input v-model="paramForm.appsecret"></el-input>
            </el-form-item>
            <el-form-item label="商户ID" prop="partnerid">
              <el-input v-model="paramForm.partnerid"></el-input>
            </el-form-item>
            <el-form-item label="支付密钥" prop="paysecret">
              <el-input v-model="paramForm.paysecret"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      paramForm: {
        appid: "",
        appSecret: "",
        partnerid: "",    //商户ID
        paysecret: ""     //支付密钥
      },
      paramFormRules:{
        appname:[
           { required:'true', message:'小程序名称',trigger:'blur' }
        ],
        appid:[
           { required:'true', message:'请填写APPID',trigger:'blur' }
        ],
        appsecret:[
           { required:'true', message:'请填写appsecret',trigger:'blur' }
        ],
        partnerid:[
           { required:'true', message:'请填写商户ID',trigger:'blur' }
        ],
        paysecret:[
           { required:'true', message:'请填写支付密钥',trigger:'blur' }
        ]
      }
    }
  },
  created(){
    this.getParams()
  },
  methods: {
    async getParams(){
      const {data: res} = await this.$http.get('params')
      if(res.code !== 200){
        this.$message.error('获取失败')
      }
      console.log(res)
      this.paramForm = res.data
    },
    onSubmit(){
       this.$refs.paramFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        
        const {data: res} = await this.$http.put('params',this.paramForm)
          if(res.code !== 200){
            return this.$message.error(res.msg)
          }
          this.$message.success(res.msg)
      });
    }
  }
};
</script>
