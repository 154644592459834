<template>
<div>
  <el-card>
    <div slot="header" class="clearfix">
      <span>概览</span>
    </div>

    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content bg-danger">
          <div class="count">{{homeData.movies}}</div>
          <div class="title">电影（部）</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-warning">
          <div class="count">{{homeData.fm}}</div>
          <div class="title">电台（部）</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-success">
          <div class="count">{{homeData.users}}</div>
          <div class="title">用户（人）</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="count">{{homeData.amount}}</div>
          <div class="title">收入（元）</div>
        </div>
      </el-col>
    </el-row>
  </el-card>

  <el-card style="margin-top:15px;">
    <div slot="header" class="clearfix">
      <span>数据统计</span>
    </div>
    <!-- 为 ECharts 准备一个具备大小（宽高）的 DOM -->
    <!-- <el-row>
      <el-col :span="16">
        <div id="main" style="width: 800px;height:400px;" ref="echarts2"></div>
      </el-col>
      <el-col :span="8">
        <div id="main" style="width: 400px;height:400px;" ref="echarts"></div>
      </el-col>
    </el-row> -->

  </el-card>
</div>
</template>

<script>
// import echarts from 'echarts'
export default {
  data() {
    return {
      homeData:[],
      // 指定图表的配置项和数据
      option: {
        title: {
          text: '粉丝增长趋势'
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: 'line'
        }]
      },
      option2: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 10,
          data: ['音乐', '电台', '视频']
        },
        series: [{
          name: '播放次数',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: 335, name: '视频' },
            { value: 310, name: '音乐' },
            { value: 234, name: '电台' }
          ]
        }]
      }
    }
  },
  created() {
    this.getHomeData()
  },
  mounted() {
    // var myChart = echarts.init(this.$refs.echarts)
    // var myChart2 = echarts.init(this.$refs.echarts2)
    // // 使用刚指定的配置项和数据显示图表。
    // myChart.setOption(this.option2)
    // myChart2.setOption(this.option)
    // console.log(echarts)
  },
  methods:{
    async getHomeData(){
      const {data : res} =await this.$http.get('home')
      if(res.code !== 200){
        return this.$message.error(res.msg);
      }
      console.log(res.data)
      this.homeData = res.data
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
  color: #eee;
}

.bg-success {
  background: #67C23A;
}

.bg-warning {
  background: #E6A23C;
}

.bg-danger {
  background: #F56C6C;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #409EFF;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-content .title {
  font-size: 18px;
}

.grid-content .count {
  font-size: 44px;
}

.row-bg {
  padding: 10px 0;
  // background-color: #f9fafc;
}
</style>