<template>
  <div>
    <breadcrumb param1="电台管理" param2="电台列表" />
    <el-card>
      <el-row :gutter="15">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            class="input-with-select"
            clearable
            @clear="getFMList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getFMList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="warning"
            icon="el-icon-plus"
            @click="AddFmDialogVisible = true"
            >新建播单</el-button
          >
        </el-col>
      </el-row>

      <el-table :data="fmList" v-loading="loading">
        <el-table-column label="节目标题">
          <template slot-scope="scope">
            <div class="album_list">
              <div class="song_image">
                <el-image
                  style="width: 64px; height: 64px;border-radius:6px;"
                  :src="scope.row.fm_image"
                ></el-image>
                <div class="play-button-wrapper">
                  <div class="music-play-button">
                    <i class="iconfont icon-play"></i>
                  </div>
                </div>
              </div>
              <div class="album_name">
                <span class="uy-text">{{ scope.row.fm_title }}</span>
                <span class="uy-text" style="color:#909399;font-size:13px;"
                  >{{ scope.row.fm_cate_name }} #</span
                >
                <span style="color:#909399;font-size:13px;"
                  >上传于 {{ scope.row.fm_add_time }}</span
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="资源ID"
          sortable
          width="150"
          prop="id"
        ></el-table-column>
        <el-table-column label="作者" sortable width="240">
          <template slot-scope="scope">
            <span class="uy-text">
              {{ scope.row.fm_auther }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          label="更新时间"
          sortable
          width="250"
          prop="fm_update_time"
        ></el-table-column>
        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <el-row>
              <el-button
                type="warning"
                icon="el-icon-folder-add"
                size="mini"
                @click="goFmListPage(scope.row.id)"
                >内容</el-button>
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row.id)"
                >编辑</el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteFm(scope.row.id)"
                >删除</el-button>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[7, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加专辑对话框 -->
    <el-dialog title="新建播单" :visible.sync="AddFmDialogVisible" width="30%">
      <el-form
        :model="AddFmForm"
        :rules="AddFmFormRules"
        ref="AddFmFormRef"
        label-width="100px"
      >
        <el-form-item label="节目名称" prop="fm_title">
          <el-input
            v-model="AddFmForm.fm_title"
            placeholder="请填写节目名称"
            class="uy-text"
          ></el-input>
        </el-form-item>

        <el-form-item label="作者" prop="fm_auther">
          <el-input
            v-model="AddFmForm.fm_auther"
            placeholder="请填写作者名称"
            class="uy-text"
          ></el-input>
        </el-form-item>

        <el-form-item label="选择分类" prop="fm_cate_id" class="uy-text">
          <el-select
            v-model="AddFmForm.fm_cate_id"
            placeholder="请选择分类"
            style="width:100%;"
          >
            <el-option
              v-for="item in fmCateList"
              :key="item.id"
              :label="item.fm_cate_name"
              :value="item.id"
              style="cate_list"
            >
              <span style="float:right;" class="uy-text">{{
                item.fm_cate_name
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="节目海报" prop="fm_image">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl+'image'"
            :headers="headerObj"
            :show-file-list="false"
            :on-success="handleImageSuccess"
            :before-upload="beforeImageUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="节目简介">
          <el-input
            type="textarea"
            rows="3"
            placeholder="请填写节目简介"
            v-model="AddFmForm.fm_desc"
            class="uy-text"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddFmDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFMCate">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑电台对话框 -->
    <el-dialog title="编辑播单" :visible.sync="EditFmDialogVisible" width="30%">
      <el-form
        :model="EditFmForm"
        :rules="EditFmFormRules"
        ref="EditFmFormRef"
        label-width="100px"
      >
        <el-form-item label="节目名称" prop="fm_title">
          <el-input
            v-model="EditFmForm.fm_title"
            placeholder="请填写节目名称"
            class="uy-text"
          ></el-input>
        </el-form-item>

        <el-form-item label="作者" prop="fm_auther">
          <el-input
            v-model="EditFmForm.fm_auther"
            placeholder="请填写作者名称"
            class="uy-text"
          ></el-input>
        </el-form-item>

        <el-form-item label="选择分类" prop="fm_cate_id" class="uy-text">
          <el-select
            v-model="EditFmForm.fm_cate_id"
            placeholder="请选择分类"
            style="width:100%;"
          >
            <el-option
              v-for="item in fmCateList"
              :key="item.id"
              :label="item.fm_cate_name"
              :value="item.id"
              style="cate_list"
            >
              <span style="float:right;" class="uy-text">{{
                item.fm_cate_name
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="节目海报" prop="fm_image">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl+'image'"
            :headers="headerObj"
            :show-file-list="false"
            :on-success="handleEditImageSuccess"
            :before-upload="beforeImageUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="节目简介">
          <el-input
            type="textarea"
            rows="3"
            placeholder="请填写节目简介"
            v-model="EditFmForm.fm_desc"
            class="uy-text"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditFmDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editFMCate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryInfo: {
        query: "",
        page: 1,
        pagesize: 7
      },
      loading: true,
      total: 0,
      fmList: [],
      fmCateList: [],
      AddFmForm: {
        fm_title: "",
        fm_auther: "",
        fm_cate_id: "",
        fm_image: "",
        fm_desc: ""
      },
      AddFmFormRules: {
        fm_title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        fm_auther: [
          { required: true, message: "请输入作者名称", trigger: "blur" }
        ],
        fm_cate_id: [
          { required: true, message: "请选择分类", trigger: "blur" }
        ],
        fm_image: [{ required: true, message: "请上传海报图", trigger: "blur" }]
      },
      EditFmFormRules: {},
      AddFmDialogVisible: false,
      EditFmDialogVisible: false,
      EditFmForm: {},
      imageUrl: "",
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem("token")
      }
    };
  },
  created() {
    this.getFMCateList();
    this.getFMList();
  },
  methods: {
    async getFMList() {
      const { data: res } = await this.$http.get("fm", {
        params: this.queryInfo
      });
      if (res.code !== 200) {
        this.loading = false;
        return this.$message.error("获取失败");
      }
      this.total = res.data.total;
      this.fmList = res.data.data;
      this.loading = false;
    },
    async getFMCateList() {
      const { data: res } = await this.$http.get("fm_cate");
      if (res.code !== 200) {
        return this.$message.error("获取失败");
      }
      this.fmCateList = res.data.data;
    },
    addFMCate() {
      this.$refs.AddFmFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const { data: res } = await this.$http.post("fm", this.AddFmForm);
        if (res.code !== 200) {
          return this.$message.error("添加失败");
        }
        this.$message.success("添加成功");
        this.getFMList();
        this.AddFmDialogVisible = false;
      });
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("fm/" + id);
      if (res.code !== 200) {
        this.$message.error("获取数据失败");
      }
      this.EditFmForm = res.data;
      this.imageUrl = res.data.fm_image_url;
      this.EditFmDialogVisible = true;
    },
    async editFMCate() {
      this.$refs.EditFmFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const { data: res } = await this.$http.put("fm", this.EditFmForm);
        if (res.code !== 200) {
          return this.$message.error("修改失败");
        }
        this.$message.success("修改成功");
        this.getFMList();
        this.EditFmDialogVisible = false;
      });
    },
    handleImageSuccess(res, file) {
      console.log(res.url);
      this.AddFmForm.fm_image = res.save_path;
      if (res.code !== 200) {
        this.$message.error("图片上传失败");
      }
      this.imageUrl = res.url;
      this.$message.success(res.msg);
    },
    handleEditImageSuccess(res) {
      if (res.code !== 200) {
        this.$message.error("图片上传失败");
      }
      this.EditFmForm.fm_image = res.save_path;
      this.imageUrl = res.url;
      this.$message.success(res.msg);
    },

    beforeImageUpload(file) {
      const isImage = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        this.$message.error("上传节目海报只能是 JPG|PNG 格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传节目海报大小不能超过 5MB!");
      }
      return isImage && isLt5M;
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage;
      this.getFMList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getFMList();
    },
    async deleteFm(id) {
      const confirmRes = await this.$confirm(
        "此操作将永久删除该分类, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(err => err);

      if (confirmRes !== "confirm") {
        return this.$message.info("取消删除");
      }

      const { data: res } = await this.$http.delete("fm/" + id);
      if (res.code !== 201) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getFMList();
    },
    goFmListPage(id){
      this.$router.push({ path: 'voice', query: { id: id } })
    }
  }
};
</script>
<style scoped>
.album_list {
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.album_list .album_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  margin-left: 30px;
}

.song_image i {
  position: relative;
  color: #fff;
  font-size: 20px;
  z-index: 100;
}

.play-button-wrapper {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 12px;
  left: 10px;
  width: 64px;
  height: 64px;
  border-radius: 6px;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.play-button-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.play-button-wrapper .music-play-button {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #fff;
}
</style>
