import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/home/Home.vue'
import Login from '../views/Login'
import Welcome from '../views/home/Welcome'
import FMCate from '../views/fm/Cate'
import FMList from '../views/fm/List'
import Report from '../views/home/Report'
import FMVoice from '../views/fm/Voice'
import Password from '../views/sys/Password'
import Movie from '../views/movie/List'
import MovieCate from '../views/movie/Category'
import AddMovie from '../views/movie/Add'
import EditMovie from '../views/movie/Edit'
import MovieList from '../views/movie/MovieList'
import Param from '../views/wechat/Param'
import About from '../views/wechat/About'
import Vip from '../views/wechat/Vip'

import Banner from '../views/wechat/Banner'
import Users from '../views/users/Users'
import Order from '../views/order/order'

import Commit from '../views/commit/commit'
import Menus from '../views/wechat/Menus'


Vue.use(Router)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children:[
      { path: '/home', component: Welcome },
      { path: '/fm/cate', component: FMCate },
      { path: '/fm/list', component: FMList },
      { path: '/report', component: Report },
      { path: '/fm/voice', component: FMVoice },
      { path: '/sys/password', component: Password },
      { path: '/movie', component: Movie },
      { path: '/movie/cate', component: MovieCate },
      { path: '/movie/add', component: AddMovie },
      { path: '/movie/edit', component: EditMovie },
      { path: '/movie/list', component: MovieList },
      { path: '/wechat/banner', component: Banner },
      { path: '/wechat/menus', component: Menus },
      { path: '/wechat/param', component: Param },
      { path: '/wechat/about', component: About },
      { path: '/wechat/vip', component: Vip },
      { path: '/users', component: Users },
      { path: '/order', component: Order },
      { path: '/commit', component: Commit },
    ]
  }
]

const router = new Router({
  routes
})

// 加载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // 如果用户访问的登录页面、直接放行
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token、强制跳转到登录页面
  if (!tokenStr) {
    return next('/login')
  }
  next()
})

export default router
