<template>
<div class="login_container">

  <div class="login_box">
    <h3 style="text-align:center;font-size:24px;color:#2c3e50;font-family:PingFang SC;">Mastan 电影管理后台</h3>
    <!-- 登录表单区域 -->
    <el-form :model="loginForm" ref="login_ref" :rules="login_rules" label-width="0px" class="login_form">
      <!-- 用户名 -->
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" placeholder="用户名/手机号" prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <!-- 密码 -->
      <el-form-item prop="password" style="margin-bottom:10px;">
        <el-input v-model="loginForm.password" placeholder="密码" prefix-icon="el-icon-lock" type="password"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom:10px;">
        <el-checkbox label="记住我的账号"></el-checkbox>
      </el-form-item>
      <!-- 按钮区域 -->
      <el-form-item>
        <el-button type="primary" @click="login" class="loginBtn">登录</el-button>
      </el-form-item>
    </el-form>
  </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      //   表单的验证规则对象
      login_rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在3到10个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在6到15个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    reset_login_form() {
      this.$refs.login_ref.resetFields()
    },
    login() {
      this.$refs.login_ref.validate(async (valid) => {
        if (!valid) { return false }
        const { data: result } = await this.$http.post('login', this.loginForm)
        if (result.code !== 200) {
          return this.$message.error(result.msg)
        }
        window.sessionStorage.setItem('token', result.token)
        this.$message.success('登录成功')

        this.$router.push('/home')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
  // background-image: url('https://icweiliimg1.pstatp.com/weili/lp/902850624442204170.webp');
  // background-size: 100%;
}

.login_box {
  width: 450px;
  height: 330px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
}

.loginBtn {
  width: 100%;
}
</style>
