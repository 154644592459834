<template>
  <div>
    <breadcrumb param1="评论管理" param2="评论列表" />
    <el-card>
      <el-table :data="commitList" v-loading="loading">
        <el-table-column type="index" label="#" width="50"></el-table-column>

        <el-table-column label="用户" width="200">
          <template slot-scope="scope">
            <span class="user-info">
              <el-image
                style="width: 36px; height: 36px; border-radius: 36px;margin-right:10px;"
                :src="scope.row.avatarUrl"
              ></el-image>
              {{ scope.row.nickName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="评论内容">
          <template slot-scope="scope">
            <span class="uy-text">
              {{ scope.row.content }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="" width="250">
          <template slot-scope="scope">
            <span class="user-info">
              <el-image
                style="width: 36px; height: 52px; border-radius: 3px;margin-right:15px;"
                :src="scope.row.movie_image_url"
              ></el-image>
              {{ scope.row.movie_title }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="获赞"
          prop="likes"
          width="100"
        ></el-table-column>
         <el-table-column label="评论时间" width="200">
          <template slot-scope="scope">
            <span class="uy-text">
              {{ scope.row.create_time }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-row>
              <el-button
                type="text"
                size="mini"
                @click="deleteCommit(scope.row.id)"
                >删除</el-button
              >
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      commitList: [],
      queryInfo: {
        query: "",
        page: 1,
        pagesize: 10,
      },
      total: 0,
      loading: true,
    };
  },
  created() {
    this.getCommitList();
  },
  methods: {
    async getCommitList() {
      const { data: res } = await this.$http.get("commit", {
        params: this.queryInfo,
      });
      if (res.code !== 200) {
        this.loading = false;
        return this.$message.error(res.msg);
      }
      this.commitList = res.data.data;
      this.total = res.data.total;
    this.loading = false;
    },
    async deleteCommit(id) {
      const confirmRes = await this.$confirm(
        "此操作将永久删除该分类, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRes !== "confirm") {
        return this.$message.info("取消删除");
      }

      const { data: res } = await this.$http.delete("commit/" + id);
      if (res.code !== 201) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getCommitList();
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage;
      this.getCommitList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getCommitList();
    },
  },
};
</script>
<style scoped>
.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
