<template>
  <div>
    <breadcrumb param1="电台管理" param2="电台列表" />
    <el-card>
      <el-page-header @back="goBack"></el-page-header>
      <div class="vocie">
        <el-row>
          <el-col :span="12">
            <div class="album_list">
              <div class="fm_image">
                <el-image
                  style="width: 132px; height: 192px; border-radius: 5px"
                  :src="movieInfo.movie_image"
                ></el-image>
              </div>
              <div class="album_name">
                <span class="uy-text">{{ movieInfo.movie_title }}</span>
                <span
                  class="uy-text ltr"
                  style="color: #606266; font-size: 14px"
                >
                  分类 : {{ movieInfo.movie_fenlei_name.movie_cate_title }} |
                  {{ movieInfo.movie_leixing_name.movie_cate_title }} |
                  {{ movieInfo.movie_yuyan_name.movie_cate_title }} |
                  {{ movieInfo.movie_diqu_name.movie_cate_title }} |
                  {{ movieInfo.movie_nianfen_name.movie_cate_title }}</span
                >
                <span style="color: #606266; font-size: 14px"
                  >添加时间：{{ movieInfo.movie_create_time }}</span
                >
                <div class="movie-desc">
                  电台简介 ：<span class="uy-text">{{
                    movieInfo.movie_desc
                  }}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="fm-info">
              <span>总集：{{ movieInfo.movie_zongji }}</span>
              <span>最后更新：{{ movieInfo.movie_update_time }}</span>
            </div>
          </el-col>
        </el-row>
        <el-divider content-position="left"></el-divider>
        <el-row>
          <el-col :span="12">
            <el-button
              type="warning"
              icon="el-icon-plus"
              @click="addDialogVisible = true"
              >添加电影</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="movieList">
          <el-table-column label="标题">
            <template slot-scope="scope">
              <span class="uy-text" style="direction: rtl; ">
                <div>{{ scope.row.movie_list_title }}</div>
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="movie_list_views" label="播放" width="120">
          </el-table-column>

          <el-table-column
            prop="movie_list_update_time"
            label="最后更新"
            width="220"
          >
          </el-table-column>

          <el-table-column label="是否收费" width="150">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.movie_list_is_vip === 0" type="info"
                >免费</el-tag
              >
              <el-tag v-if="scope.row.movie_list_is_vip === 1" type="success"
                >VIP</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="showEditDialog(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="deleteMovieList(scope.row.id)"
                  >删除</el-button
                >
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.page"
          :page-sizes="[10, 15, 20]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>

        <!-- 添加电台列表对话框  -->
        <el-dialog
          title="添加电影"
          :visible.sync="addDialogVisible"
          width="40%"
        >
          <el-form
            :model="addForm"
            :rules="addFormRules"
            ref="addFormRef"
            prop="addForm"
            label-width="100px"
            class="demo-addForm"
          >
            <el-form-item label="电影标题" prop="movie_list_title">
              <el-input
                v-model="addForm.movie_list_title"
                class="uy-text"
              ></el-input>
            </el-form-item>
            <el-form-item label="资源类型" prop="movie_list_url_type">
              <el-radio-group
                v-model="addForm.movie_list_url_type"
                @change="typeChange"
              >
                <el-radio label="1">公众号视频</el-radio>
                <el-radio label="0">本地文件</el-radio>
                <el-radio label="2">URL</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="资源ID" v-if="!showDogeCloudInput">
              <el-input
                v-model="addForm.movie_list_wxv_id"
                :disabled="type_visible"
                placeholder="wxv_1234567878abc"
              ></el-input>
            </el-form-item>
            <el-form-item label="视频链接" v-if="showDogeCloudInput">
              <el-input
                v-model="addForm.movie_list_dogecloud_url"
                placeholder="请输入播放链接"
              ></el-input>
            </el-form-item>
            <el-form-item label="电影文件" prop="movie_list_file_url">
              <el-upload
                class="upload-demo"
                :action="uploadUrl + 'video'"
                ref="upload"
                :headers="headerObj"
                :limit="1"
                :before-upload="beforeVideoUpload"
                :on-success="videoUploadSuccess"
              >
                <el-button size="small" type="primary" icon="el-icon-upload"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  <el-alert
                    title="请上传mp4格式文件 、规格：360P,720P,1080P"
                    type="warning"
                    :closable="false"
                  ></el-alert>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="VIP观看" prop="movie_list_is_vip">
              <el-radio-group v-model="addForm.movie_list_is_vip">
                <el-radio label="0">关闭</el-radio>
                <el-radio label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
             <el-form-item label="购买收看" prop="movie_list_is_buy">
              <el-radio-group v-model="addForm.movie_list_is_buy" @change="radio_buy_change">
                <el-radio label="0">关闭</el-radio>
                <el-radio label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
             <el-form-item label="价格(元)" v-if="isShowBuyInput">
                <el-input v-model="addForm.movie_list_price" placeholder="电影价格:例如 3" type="number"></el-input>
            </el-form-item>
            <el-form-item label="电影备注" class="uy-text">
              <el-input
                type="textarea"
                v-model="addForm.movie_list_desc"
                rows="4"
                maxlength="300"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addMovieList">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 编辑电台列表对话框  -->
        <el-dialog
          title="编辑电影"
          :visible.sync="editDialogVisible"
          width="40%"
        >
          <el-form
            :model="editForm"
            :rules="editFormRules"
            ref="editFormRef"
            prop="editForm"
            label-width="100px"
            class="demo-addForm"
          >
            <el-form-item label="电影标题" prop="movie_list_title">
              <el-input
                v-model="editForm.movie_list_title"
                class="uy-text"
              ></el-input>
            </el-form-item>
            <el-form-item label="资源类型" prop="movie_list_url_type">
              <el-radio-group
                v-model="editForm.movie_list_url_type"
                @change="typeChangeEdit"
              >
                <el-radio :label="1">公众号视频</el-radio>
                <el-radio :label="0">本地文件</el-radio>
                <el-radio :label="2">URL</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="资源ID">
              <el-input
                v-model="editForm.movie_list_wxv_id"
                :disabled="type_visible_edit"
                placeholder="wxv_1234567878abc"
              ></el-input>
            </el-form-item>
              <el-form-item label="视频链接">
              <el-input
                v-model="editForm.movie_list_dogecloud_url"
                placeholder="请输入播放链接"
              ></el-input>
            </el-form-item>
            <el-form-item label="电影文件" prop="movie_list_file_url">
              <el-upload
                class="upload-demo"
                :action="uploadUrl + 'video'"
                :headers="headerObj"
                :limit="1"
                :before-upload="beforeVideoUpload"
                :on-success="editVideoUploadSuccess"
              >
                <el-button size="small" type="primary" icon="el-icon-upload"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  <el-alert
                    title="请上传mp4格式文件 、规格：360P,720P,1080P"
                    type="warning"
                    :closable="false"
                  ></el-alert>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="VIP收听" prop="movie_list_is_vip">
              <el-radio-group v-model="editForm.movie_list_is_vip">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="购买收看" prop="movie_list_is_buy">
              <el-radio-group v-model="editForm.movie_list_is_buy" @change="radio_edit_buy_change">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="价格(元)">
                <el-input v-model="editForm.movie_list_price" placeholder="电影价格:例如 3" type="number"></el-input>
            </el-form-item>
            <el-form-item label="电影备注">
              <el-input
                type="textarea"
                v-model="editForm.movie_list_desc"
                rows="3"
                maxlength="300"
                show-word-limit
                class="uy-text"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="editMovieList">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      movieList: [],
      type_visible: false,
      type_visible_edit:false,
      showDogeCloudInput:false,
      showDogeCloudInputEdit:false,
      isShowBuyInput:false,
      isShowEditBuyInput:false,
      movieInfo: {
        movie_cate_title: "",
      },
      addForm: {
        movie_list_title: "",
        movie_list_wxv_id: "",
        movie_list_file_url: "",
        movie_list_url_type: "1",
        movie_list_is_vip: "0",
        movie_list_is_buy:"0",
        movie_list_price:'',
        movie_list_desc: "",
        movie_id: this.$route.query.id,
      },
      editForm: {},
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      addFormRules: {
        movie_list_title: [
          { required: "true", message: "请输入节目标题", trigger: "blur" },
        ],
        // movie_list_file_url:[
        //   {required:'true',message:'请上传电影文件',trigger:'blur'}
        // ]
      },
      editFormRules: {
        movie_list_title: [
          { required: "true", message: "请输入节目标题", trigger: "blur" },
        ],
        // movie_list_file_url:[
        //   {required:'true',message:'请上传电影文件',trigger:'blur'}
        // ]
      },
      queryInfo: {
        query: "",
        page: 1,
        pagesize: 10,
      },
      total: 0,
    };
  },
  created() {
    this.getMovieInfo();
    this.getMovieList();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getMovieInfo() {
      const { data: res } = await this.$http.get(
        "movie/" + this.$route.query.id
      );
      console.log(res);
      if (res.code !== 200) {
        this.$message.error(res.msg);
      }
      this.movieInfo = res.data;
    },
    async getMovieList() {
      const { data: res } = await this.$http.get(
        "movielist/" + this.$route.query.id,
        { params: this.queryInfo }
      );
      if (res.code !== 200) {
        return this.$message.error("获取失败");
      }
      this.total = res.data.total;
      this.movieList = res.data.data;
    },
    async addMovieList() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const { data: res } = await this.$http.post("movielist", this.addForm);
        if (res.code !== 200) {
          return this.$message.error(res.msg);
        }
        this.$message.success(res.msg);
        this.getMovieList();
        this.addDialogVisible = false;
        this.$refs.addFormRef.resetFields();
        this.addForm.movie_list_desc = "";
        this.$refs.upload.clearFiles();
      });
    },
    editMovieList() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const { data: res } = await this.$http.put("movielist", this.editForm);
        if (res.code !== 200) {
          return this.$message.error("修改失败");
        }
        this.$message.success("修改成功");
        this.getMovieList();
        this.editDialogVisible = false;
        this.editForm = {};
      });
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get(`movielist/${id}/info`);
      if (res.code !== 200) {
        return this.$message.error("获取失败");
      }

      this.editForm = res.data;
      console.log(this.editForm);
      this.editDialogVisible = true;
    },
    async deleteMovieList(id) {
      const confirmRes = await this.$confirm(
        "此操作将永久删除该分类, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRes !== "confirm") {
        return this.$message.info("取消删除");
      }

      const { data: res } = await this.$http.delete("movielist/" + id);
      if (res.code !== 201) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getMovieList();
    },

    beforeVideoUpload(file) {
      const isVideo = file.type === "video/mp4";
      if (!isVideo) {
        this.$message.error("请上传MP4格式的音频文件");
      }
      return isVideo;
    },
    videoUploadSuccess(res, file) {
      if (res.code !== 200) {
        return this.$message.error("上传失败");
      }
      this.addForm.movie_list_file_url = res.save_path;
      this.$message.success("上传成功");
    },
    editVideoUploadSuccess(res, file) {
      if (res.code !== 200) {
        return this.$message.error("上传失败");
      }
      this.editForm.movie_list_file_url = res.save_path;
      this.$message.success("上传成功");
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage;
      this.getMovieList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getMovieList();
    },
    typeChange() {
      this.type_visible =
        this.addForm.movie_list_url_type === "0" ? true : false;
      this.showDogeCloudInput =  this.addForm.movie_list_url_type == '2' ? true : false
    },
    typeChangeEdit(e) {
      console.log('change',e)
      this.type_visible_edit = this.editForm.movie_list_url_type == '0' ? true : false;
      this.showDogeCloudInputEdit =  this.editForm.movie_list_url_type == '2' ? true : false
    },
    radio_buy_change(){
      this.isShowBuyInput = this.addForm.movie_list_is_buy == '1' ? true : false 
    },
    radio_edit_buy_change(){
      this.isShowEditBuyInput = this.editForm.movie_list_is_buy == "1" ? true : false 
    }
  },
};
</script>

<style scoped>
.vocie {
  margin-top: 30px;
}
.album_list {
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.album_list .album_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  /* justify-content: space-between; */
  margin-left: 30px;
}

.album_list span {
  margin-bottom: 15px;
}

.fm_image i {
  position: relative;
  color: #fff;
  font-size: 20px;
  z-index: 100;
}
.fm-info {
  display: flex;
  flex-direction: column;
  /* height: 116px; */
  justify-content: flex-start;
  color: #606266;
  font-size: 14px;
}

.fm-info span {
  margin-bottom: 15px;
}
.movie-desc {
  /* margin-top: 15px; */
  color: #606266;
  /* margin-left:162px; */
  font-size: 14px;
  line-height: 32px;
}
</style>
