<template>
    <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>{{param1}}</el-breadcrumb-item>
    <el-breadcrumb-item>{{param2}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
  name:'Breadcrumb',
  props:{
    param1:String,
    param2:String
  }
}
</script>