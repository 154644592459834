<template>
  <div>
    <breadcrumb param1="电影" param2="电影列表" />
    <el-card>
      <el-row :gutter="15">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            class="input-with-select"
            clearable
            @clear="getMovieList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getMovieList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="warning" icon="el-icon-plus" @click="goAddMovie"
            >新增电影</el-button
          >
        </el-col>
        <el-col :span="4" :offset="8">
          <el-switch
            v-model="switchValue"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="全部上架"
            inactive-text="全部下架"
            @change="onOffLineAll"
            active-value="0" 
            inactive-value="1"
          >
          </el-switch>
        </el-col>
      </el-row>

      <el-table :data="movieList" v-loading="loading">
        <el-table-column label="电影海报">
          <template slot-scope="scope">
            <div class="album_list">
              <div class="song_image">
                <el-image
                  style="width: 84px; height: 116px;border-radius:6px;"
                  :src="scope.row.movie_image"
                ></el-image>
                <div class="play-button-wrapper">
                  <div class="music-play-button">
                    <i class="iconfont icon-play"></i>
                  </div>
                </div>
              </div>
              <div class="album_name">
                <span class="uy-text">{{ scope.row.movie_title }}</span>
                <span class="uy-text" style="color:#909399;font-size:13px;"
                  >{{ scope.row.movie_cate_title }} #</span
                >
                <span style="color:#909399;font-size:13px;"
                  >上传于 {{ scope.row.movie_create_time }}</span
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="资源ID"
          sortable
          width="150"
          prop="id"
        ></el-table-column>
        <el-table-column
          label="总集"
          sortable
          width="100"
          prop="movie_zongji"
        ></el-table-column>
        <el-table-column label="上架/下架" width="150">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.movie_is_online" :active-value="0" :inactive-value="1" @change="changeIsOnline(scope.row.id)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="更新时间"
          sortable
          width="250"
          prop="movie_update_time"
        ></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-row>
              <el-button
                type="warning"
                icon="el-icon-folder-add"
                size="mini"
                @click="goMovieListPage(scope.row.id)"
                >电影集</el-button
              >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="goEditPage(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteMovie(scope.row.id)"
                >删除</el-button
              >
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[7, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryInfo: {
        query: "",
        page: 1,
        pagesize: 7,
      },
      movieList: [],
      loading: true,
      total: 0,
      switchValue: true,
    };
  },
  created() {
    this.getMovieList();
  },
  methods: {
    async getMovieList() {
      const { data: res } = await this.$http.get("movie", {
        params: this.queryInfo,
      });
      if (res.code !== 200) {
        return this.$message.error(res.msg);
      }
      this.movieList = res.data.data;
      this.total = res.data.total;
      this.loading = false;
      this.$message.success(res.msg);
    },
    goAddMovie() {
      this.$router.push("movie/add");
    },
    goMovieListPage(id) {
      this.$router.push({ path: "movie/list", query: { id: id } });
    },
    goEditPage(id) {
      this.$router.push({ path: "movie/edit", query: { id: id } });
    },
    async deleteMovie(id) {
      const confirmRes = await this.$confirm(
        "此操作将永久删除该分类, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRes !== "confirm") {
        return this.$message.info("取消删除");
      }

      const { data: res } = await this.$http.delete("movie/" + id);
      if (res.code !== 201) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getMovieList();
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage;
      this.getMovieList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getMovieList();
    },
    async changeIsOnline(id){
      const {data : res} = await this.$http.get('movie/is_online/' + id)
      if(res.code !== 200){
        return this.$message.error(res.msg);
      }
      this.$message.success(res.msg);
    },
    async onOffLineAll(type){
      const {data : res} = await this.$http.get('movie/offline_all/' + type)
      if(res.code !== 200){
        return this.$message.error(res.msg);
      }
      this.$message.success(res.msg);
      this.getMovieList()
    }
  },
};
</script>
<style scoped>
.album_list {
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.album_list .album_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  margin-left: 30px;
}

.song_image i {
  position: relative;
  color: #fff;
  font-size: 20px;
  z-index: 100;
}

.play-button-wrapper {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 12px;
  left: 10px;
  width: 84px;
  height: 116px;
  border-radius: 5px;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.play-button-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.play-button-wrapper .music-play-button {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #fff;
}
</style>
