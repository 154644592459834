<template>
  <div>
    <breadcrumb param1="电影" param2="添加电影" />
    <el-card>
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="100px">
       <el-row>
         <el-col :span="12">
            <el-form-item label="电影标题" prop="movie_title">
              <el-input v-model="editForm.movie_title"></el-input>
            </el-form-item>
            <el-form-item label="电影海报" prop="movie_image_url">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl + 'image'"
                :headers="headerObj"
                :show-file-list="false"
                :on-success="handleImageSuccess"
                :before-upload="beforeImageUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <el-row>
              <el-col :span="8">
                <el-form-item label="总集" prop="movie_zongji">
                  <el-input-number v-model="editForm.movie_zongji" :min="1"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="分类" prop="movie_fenlei_id">
            <el-radio-group v-model="editForm.movie_fenlei_id">
              <!--eslint-disable-next-line-->
              <el-radio v-for="(item, i) in fenlei_list" :label="item.id" class="uy-text ltr">{{item.movie_cate_title}}</el-radio>
            </el-radio-group> 
            </el-form-item>
            <el-form-item label="类型" prop="movie_leixing_id">
              <el-radio-group v-model="editForm.movie_leixing_id">
                <!--eslint-disable-next-line-->
                <el-radio v-for="(item, i) in leixing_list" :label="item.id" class="uy-text ltr">{{item.movie_cate_title}}</el-radio>
              </el-radio-group> 
            </el-form-item>
            <el-form-item label="语言" prop="movie_yuyan_id">
              <el-radio-group v-model="editForm.movie_yuyan_id">
                <!--eslint-disable-next-line-->
                <el-radio v-for="(item, i) in yuyan_list" :label="item.id" class="uy-text ltr">{{item.movie_cate_title}}</el-radio>
              </el-radio-group> 
            </el-form-item>
            <el-form-item label="地区" prop="movie_yuyan_id">
              <el-radio-group v-model="editForm.movie_diqu_id">
                <!--eslint-disable-next-line-->
                <el-radio v-for="(item, i) in diqu_list" :label="item.id" class="uy-text ltr">{{item.movie_cate_title}}</el-radio>
              </el-radio-group> 
            </el-form-item>
            <el-form-item label="年份" prop="movie_nianfen_id">
              <el-radio-group v-model="editForm.movie_nianfen_id">
                <!--eslint-disable-next-line-->
                <el-radio v-for="(item, i) in nianfen_list" :label="item.id" class="uy-text ltr">{{item.movie_cate_title}}</el-radio>
              </el-radio-group> 
            </el-form-item>
            <el-form-item label="电影介绍">
              <el-input type="textarea" v-model="editForm.movie_desc" rows="5" maxlength="500" show-word-limit></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="editMovie">立即保存</el-button>
              <el-button>重置</el-button>
            </el-form-item>

         </el-col>
       </el-row>
      </el-form>
    </el-card>
  </div>
</template>
<script>
export default {
  data(){
    return {
      editForm:{
        movie_title:'',
        movie_image_url:'',
        movie_zongji:'1',
        movie_fenlei_id:'',
        movie_leixing_id:'',
        movie_yuyan_id:'',
        movie_diqu_id:'',
        movie_nianfen_id:'',
        movie_desc:''
      },
      editFormRules:{
        movie_title:[
          { required:'true', message:'请输入电影标题',trigger:'blur' }
        ],
        movie_image_url:[
          { required:'true', message:'请上传电影海报',trigger:'blur' }
        ],
        movie_zongji:[
          { required:'true', message:'请输入总集数', trigger:'blur'}
        ],
        movie_fenlei_id:[
          { required:'true', message:'请选择分类', trigger:'blur'}
        ],
        movie_leixing_id:[
          { required:'true', message:'请选择类型', trigger:'blur'}
        ],
        movie_yuyan_id:[
          { required:'true', message:'请选择语言', trigger:'blur'}
        ],
        movie_diqu_id:[
          { required:'true', message:'请选择地区', trigger:'blur'}
        ],
        movie_nianfen_id:[
          { required:'true', message:'请选择年份', trigger:'blur'}
        ]
      },
      fenlei_list:[],
      leixing_list:[],
      yuyan_list:[],
      diqu_list:[],
      nianfen_list:[],
      imageUrl:'',
        // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem("token")
      }
    }
  },
  created(){
    this.getMovieCateList()
    this.getMovieInfo()
  },
  methods:{
    async getMovieCateList(){
      const {data: res} =await this.$http.get('movie_cate')
      if(res.code !== 200){
        return this.$message.error(res.msg)
      }
      res.data.forEach(val => {
        switch(val.movie_cate_type){
          case 1:
            this.fenlei_list.push(val)
            break;
          case 2:
            this.leixing_list.push(val)
            break;
          case 3:
            this.yuyan_list.push(val)
            break;
          case 4:
            this.diqu_list.push(val)
            break;
          case 5:
            this.nianfen_list.push(val)
            break;
        }
      });
    },
    async getMovieInfo(){
      const {data : res} = await this.$http.get('movie/' + this.$route.query.id)
      if(res.code !== 200){
        return this.$message.error(res.msg)
      }
      this.editForm = res.data
      this.imageUrl = res.data.movie_image
    },
    editMovie(){
       this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const {data: res} = await this.$http.put('movie',this.editForm)
        if(res.code !== 200){
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.$router.push('/movie')
      });
    },
    handleImageSuccess(res , file){
      if(res.code !== 200){
        return this.$message.error('图片上传失败')
      }
      this.editForm.movie_image_url = res.save_path
      this.imageUrl = res.url
      this.$message.success('上传成功')
    },
    beforeImageUpload(file){
      const isImage = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        this.$message.error("上传节目海报只能是 JPG|PNG 格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传节目海报大小不能超过 5MB!");
      }
      return isImage && isLt5M;
    }
  }
}
</script>
<style scoped>
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 170px;
    line-height: 170px;
    text-align: center;
}
.avatar {
    width: 130px;
    height: 170px;
    display: block;
}
</style>