import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element'
import './assets/fonts/iconfont.css'
import './assets/css/global.css'
import axios from 'axios'
// 引入全局变量
import uploadUrl from './components/Global'

//引入组件
import Breadcrumb from './components/Breadcrumb'

//全局注册组件
Vue.component('Breadcrumb',Breadcrumb)    
Vue.config.productionTip = false

Vue.prototype.uploadUrl = uploadUrl.uploadUrl

axios.defaults.baseURL = "https://api.mastan.mldqanal.cn/pc"
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})

axios.interceptors.response.use(config => {
  if (config.data.status === 1002 || config.data.status === 1003 || config.data.status === 1004) {
    window.sessionStorage.clear()
    router.replace({
      path: '/login'
    })
  }
  return config
})
Vue.prototype.$http = axios



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
