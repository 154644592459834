<template>
  <div>
    <breadcrumb param1="电台管理" param2="电台列表" />
    <el-card>
      <el-page-header @back="goBack"></el-page-header>
      <div class="vocie">
        <el-row>
          <el-col :span="12">
            <div class="album_list">
              <div class="fm_image">
                <el-image
                  style="width: 92px; height: 92px; border-radius: 6px"
                  :src="fmInfo.fm_image_url"
                ></el-image>
              </div>
              <div class="album_name">
                <span class="uy-text">{{ fmInfo.fm_title }}</span>
                <span class="uy-text" style="color: #606266; font-size: 14px"
                  >{{ fmInfo.fm_cate_name }} ： 分类</span
                >
                <span style="color: #606266; font-size: 14px"
                  >添加时间：{{ fmInfo.fm_add_time }}</span
                >
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="fm-info">
              <span
                >作者：<span class="uy-text">{{ fmInfo.fm_auther }}</span></span
              >
              <span>总集：23/32</span>
              <span>最后更新：{{ fmInfo.fm_update_time }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <div class="fm-desc">
              电台简介：<span class="uy-text">{{ fmInfo.fm_desc }}</span>
            </div>
          </el-col>
        </el-row>
        <el-divider content-position="left"></el-divider>
        <el-row>
          <el-col :span="12">
            <el-button
              type="warning"
              icon="el-icon-plus"
              @click="addDialogVisible = true"
              >添加节目</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="fmList">
          <el-table-column label="标题">
            <template slot-scope="scope">
              <span class="uy-text" style="direction: rtl; ">
                <div>{{ scope.row.fm_list_title }}</div>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="资源ID"
            sortable
            width="150"
            prop="id"
          ></el-table-column>
          <el-table-column prop="fm_list_views" label="播放" width="120">
          </el-table-column>

          <el-table-column
            prop="fm_list_update_time"
            label="最后更新"
            width="220"
          >
          </el-table-column>

          <el-table-column label="是否收费" width="150">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.fm_list_is_vip === 0" type="info"
                >免费</el-tag
              >
              <el-tag v-if="scope.row.fm_list_is_vip === 1" type="success"
                >VIP</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="showEditDialog(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="deleteFm(scope.row.id)"
                  >删除</el-button
                >
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.page"
          :page-sizes="[10, 10, 15, 20]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>

        <!-- 添加电台列表对话框  -->
        <el-dialog
          title="添加节目"
          :visible.sync="addDialogVisible"
          width="40%"
        >
          <el-form
            :model="addForm"
            :rules="addFormRules"
            ref="addFormRef"
            label-width="100px"
            class="demo-addForm"
          >
            <el-form-item label="节目标题" prop="fm_list_title">
              <el-input
                v-model="addForm.fm_list_title"
                class="uy-text"
              ></el-input>
            </el-form-item>
            <el-form-item label="资源类型" prop="fm_list_url_type">
              <el-radio-group
                v-model="addForm.fm_list_url_type"
                @change="typeChange"
              >
                <el-radio label="1">公众号音频</el-radio>
                <el-radio label="0">本地文件</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="资源ID">
              <el-input
                v-model="addForm.fm_list_media_id"
                :disabled="type_visible"
                placeholder="Mzk0MzI0MTQ1N18x..."
              ></el-input>
            </el-form-item>
            <el-form-item label="音频文件" prop="fm_list_audio_url">
              <el-upload
                class="upload-demo"
                :action="uploadUrl+'audio'"
                :headers="headerObj"
                :limit="1"
                :before-upload="beforeAudioUpload"
                :on-success="audioUploadSuccess"
              >
                <el-button size="small" type="primary" icon="el-icon-upload"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  <el-alert
                    title="请上传mp3格式文件"
                    type="warning"
                    :closable="false"
                  ></el-alert>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="VIP收听" prop="fm_list_is_vip">
              <el-radio-group v-model="addForm.fm_list_is_vip">
                <el-radio label="0">关闭</el-radio>
                <el-radio label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addFmList">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 编辑电台列表对话框  -->
        <el-dialog
          title="添加节目"
          :visible.sync="editDialogVisible"
          width="40%"
        >
          <el-form
            :model="editForm"
            :rules="editFormRules"
            ref="editFormRef"
            label-width="100px"
            class="demo-addForm"
          >
            <el-form-item label="节目标题" prop="fm_list_title">
              <el-input
                v-model="editForm.fm_list_title"
                class="uy-text"
              ></el-input>
            </el-form-item>
            <el-form-item label="资源类型" prop="fm_list_url_type">
              <el-radio-group
                v-model="editForm.fm_list_url_type"
                @change="typeChangeEdit"
              >
                <el-radio :label="1">公众号音频</el-radio>
                <el-radio :label="0">本地文件</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="资源ID">
              <el-input
                v-model="editForm.fm_list_media_id"
                :disabled="type_visible_edit"
                placeholder="Mzk0MzI0MTQ1N18x..."
              ></el-input>
            </el-form-item>
            <el-form-item label="音频文件" prop="fm_list_audio_url">
              <el-upload
                class="upload-demo"
                :action="uploadUrl + 'audio'"
                :headers="headerObj"
                :limit="1"
                :before-upload="beforeAudioUpload"
                :on-success="editAudioUploadSuccess"
              >
                <el-button size="small" type="primary" icon="el-icon-upload"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  <el-alert
                    title="请上传mp3格式文件"
                    type="warning"
                    :closable="false"
                  ></el-alert>
                </div>
              </el-upload>
              <!-- <el-button type="warning" size="small" icon="el-icon-video-play" @click="goMusicUrl">点击播放</el-button> -->
            </el-form-item>
            <el-form-item label="VIP收听" prop="fm_list_is_vip">
              <el-radio-group v-model="editForm.fm_list_is_vip">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="editFmList">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      type_visible: false,
      type_visible_edit: false,
      fmList: [],
      fmInfo: [],
      addForm: {
        fm_list_title: "",
        fm_list_audio_url: "",
        fm_list_is_vip: "0",
        fm_id: this.$route.query.id,
        fm_list_url_type: "1",
        fm_list_media_id: "",
      },
      editForm: {},
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      addFormRules: {
        fm_list_title: [
          { required: "true", message: "请输入节目标题", trigger: "blur" },
        ],
        // fm_list_audio_url: [
        //   { required: "true", message: "请上传音频文件", trigger: "blur" },
        // ],
      },
      editFormRules: {
        fm_list_title: [
          { required: "true", message: "请输入节目标题", trigger: "blur" },
        ],
        // fm_list_audio_url: [
        //   { required: "true", message: "请上传音频文件", trigger: "blur" },
        // ],
      },
      queryInfo: {
        query: "",
        page: 1,
        pagesize: 10,
      },
      total: 0,
    };
  },
  created() {
    this.getFmInfo();
    this.getFmList();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getFmInfo() {
      const { data: res } = await this.$http.get("fm/" + this.$route.query.id);
      console.log(res);
      if (res.code !== 200) {
        this.$message.error("数据获取失败");
      }
      this.fmInfo = res.data;
    },
    async getFmList() {
      const { data: res } = await this.$http.get(
        "fmlist/" + this.$route.query.id,
        { params: this.queryInfo }
      );
      if (res.code !== 200) {
        return this.$message.error("获取失败");
      }
      this.total = res.data.total;
      this.fmList = res.data.data;
      console.log(res.data.data);
    },
    async addFmList() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const { data: res } = await this.$http.post("fmlist", this.addForm);
        if (res.code !== 200) {
          return this.$message.error("添加失败");
        }
        this.$message.success("添加成功");
        this.getFmList();
        this.addDialogVisible = false;
      });
    },
    editFmList() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const { data: res } = await this.$http.put("fmlist", this.editForm);
        if (res.code !== 200) {
          return this.$message.error("修改失败");
        }
        this.$message.success("修改成功");
        this.getFmList();
        this.editDialogVisible = false;
      });
    },
    goMusicUrl() {
      var tempwindow = window.open("_blank");
      tempwindow.location = this.editForm.fm_list_audio_play_url;
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("fmlistinfo/" + id);
      if (res.code !== 200) {
        return this.$message.error("获取失败");
      }
      console.log(res);
      this.editForm = res.data;
      this.type_visible_edit = this.editForm.fm_list_url_type === 0 ? true : false;
      this.editDialogVisible = true;
    },
    async deleteFm(id) {
      const confirmRes = await this.$confirm(
        "此操作将永久删除该分类, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRes !== "confirm") {
        return this.$message.info("取消删除");
      }

      const { data: res } = await this.$http.delete("fmlist/" + id);
      if (res.code !== 201) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getFmList();
    },

    beforeAudioUpload(file) {
      const isAudio = file.type === "audio/mpeg";
      const isLt200M = file.size / 1024 / 1024 < 200;

      if (!isAudio) {
        this.$message.error("请上传MP3格式的音频文件");
      }
      if (!isLt200M) {
        this.$message.error("音频文件不能超过 20MB!");
      }
      return isAudio && isLt200M;
    },
    audioUploadSuccess(res, file) {
      if (res.code !== 200) {
        return this.$message.error("上传失败");
      }
      this.addForm.fm_list_audio_url = res.save_path;
      this.$message.success("上传成功");
    },
    editAudioUploadSuccess(res, file) {
      if (res.code !== 200) {
        return this.$message.error("上传失败");
      }
      this.editForm.fm_list_audio_url = res.save_path;
      this.$message.success("上传成功");
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage;
      this.getFmList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getFmList();
    },
    typeChange() {
      this.type_visible = this.addForm.fm_list_url_type === "0" ? true : false;
    },
    typeChangeEdit() {
      this.type_visible_edit = this.editForm.fm_list_url_type === 0 ? true : false;
    },
  },
};
</script>

<style scoped>
.vocie {
  margin-top: 30px;
}
.album_list {
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.album_list .album_name {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  margin-left: 30px;
}

.fm_image i {
  position: relative;
  color: #fff;
  font-size: 20px;
  z-index: 100;
}
.fm-info {
  display: flex;
  flex-direction: column;
  height: 92px;
  justify-content: space-between;
  color: #606266;
  font-size: 14px;
}
.fm-desc {
  margin-top: 15px;
  color: #606266;
  margin-left: 122px;
  font-size: 14px;
  line-height: 32px;
}
</style>
