<template>
  <div>
    <breadcrumb param1="电台管理" param2="电台分类" />
    <el-card>
      <el-row :gutter="15">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            class="input-with-select"
            clearable
            @clear="getFMCateList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getFMCateList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="warning"
            icon="el-icon-plus"
            @click="AddFmCateDialogVisible = true"
            >添加分类</el-button
          >
        </el-col>
      </el-row>

      <el-table :data="fmCateList" border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="分类名称">
          <template slot-scope="scope">
            <div class="uy-text">{{ scope.row.fm_cate_name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="排序" width="100">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.fm_cate_order_num"
              @blur="update_order_num(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="更新时间"
          prop="fm_cate_update_time"
          width="250"
        ></el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="deleteCate(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[7, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加专辑对话框 -->
    <el-dialog
      title="添加分类"
      :visible.sync="AddFmCateDialogVisible"
      width="30%"
    >
      <el-form
        :model="AddFmCateForm"
        :rules="AddFmCateFormRules"
        ref="AddFmCateFormRef"
        label-width="100px"
      >
        <el-form-item label="分类名称" prop="fm_cate_name">
          <el-input
            v-model="AddFmCateForm.fm_cate_name"
            placeholder="请填写分类名称"
            class="uy-text"
          ></el-input>
        </el-form-item>

        <el-form-item label="分类简介">
          <el-input
            type="textarea"
            rows="3"
            placeholder="请填写分类简介"
            v-model="AddFmCateForm.fm_cate_desc"
            class="uy-text"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="cate_order_num">
          <el-input
            v-model="AddFmCateForm.fm_cate_order_num"
            style="width:100px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddFmCateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFMCate">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑专辑对话框 -->
    <el-dialog
      title="编辑分类"
      :visible.sync="editFmCateDialogVisible"
      width="30%"
    >
      <el-form
        :model="editFmCateForm"
        :rules="editFmCateFormRules"
        ref="editFmCateFormRef"
        label-width="100px"
      >
        <el-form-item label="分类名称" prop="fm_cate_name">
          <el-input
            v-model="editFmCateForm.fm_cate_name"
            placeholder="请填写分类名称"
            class="uy-text"
          ></el-input>
        </el-form-item>

        <el-form-item label="分类简介">
          <el-input
            type="textarea"
            rows="3"
            placeholder="请填写分类简介"
            v-model="editFmCateForm.fm_cate_desc"
            class="uy-text"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="cate_order_num">
          <el-input
            v-model="editFmCateForm.fm_cate_order_num"
            style="width:100px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editFmCateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateFmCate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryInfo: {
        query: "",
        page: 1,
        pagesize: 5
      },
      total: 0,
      fmCateList: [],
      AddFmCateForm: {
        fm_cate_name: "",
        fm_cate_desc: "",
        fm_cate_order_num: 0
      },
      editFmCateForm: {},
      imageUrl: "",
      AddFmCateFormRules: {
        fm_cate_name: [
          { required: true, message: "请输入分类名称", trigger: "blur" }
        ]
      },
      editFmCateFormRules: {
        fm_cate_name: [
          { required: true, message: "请输入分类名称", trigger: "blur" }
        ]
      },
      AddFmCateDialogVisible: false,
      editFmCateDialogVisible: false,
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem("token")
      },
      loading: true
    };
  },

  created() {
    this.getFMCateList();
  },

  methods: {
    async getFMCateList() {
      const { data: res } = await this.$http.get("fm_cate",{params: this.queryInfo});
      if (res.code !== 200) {
        this.loading = false;
        return this.$message.error("获取失败");
      }
      this.total = res.data.total
      this.fmCateList = res.data.data;
      this.loading = false;
    },
    async addFMCate() {
      this.$refs.AddFmCateFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        const { data: res } = await this.$http.post(
          "fm_cate/cate",
          this.AddFmCateForm
        );
        if (res.code !== 200) {
          return this.$message.error("添加失败");
        }
        this.$message.success("添加成功");
        this.getFMCateList();
        this.AddFmCateDialogVisible = false;
      });
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage;
      this.getFMCateList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getFMCateList();
    },
    async deleteCate(id) {
      const confirmRes = await this.$confirm(
        "此操作将永久删除该分类, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(err => err);

      if (confirmRes !== "confirm") {
        return this.$message.info("取消删除");
      }

      const { data: res } = await this.$http.delete("fm_cate/cate/" + id);
      if (res.code !== 201) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getFMCateList();
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("fm_cate/cate/" + id);
      if (res.code !== 200) {
        this.$message.error("获取数据失败");
      }
      this.editFmCateForm = res.data;
      this.editFmCateDialogVisible = true;
    },

    updateFmCate() {
      this.$refs.editFmCateFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error("请填写分类名称");
        }
        const { data: res } = await this.$http.put("fm_cate/cate", this.editFmCateForm);
        if (res.code !== 201) {
          return this.$message.error("修改失败");
        }
        this.editFmCateDialogVisible = false;
        this.editFmCateForm = {};
        this.getFMCateList();
        this.$message.success("修改成功");
      });
    }
  }
};
</script>
