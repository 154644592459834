<template>
  <div>
    <breadcrumb param1="设置" param2="修改密码" />
    <el-card>
      <el-row>
        <el-col :span="8">
          <el-form ref="passFormRef" :model="passForm"  :rules="passFormRules" label-width="120px">
            <el-form-item label="登录账号" prop="username">
              <el-input v-model="passForm.username"></el-input>
            </el-form-item>
            <el-form-item label="旧密码" prop="old_password">
              <el-input v-model="passForm.old_password" type="password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
              <el-input v-model="passForm.new_password" type="password"></el-input>
            </el-form-item>
            <el-form-item label="重复新密码" prop="renew_password">
              <el-input v-model="passForm.renew_password" type="password"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">确认修改</el-button>
              <el-button>重置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data(){
    return {
      passForm:{
        username:'',
        old_password:'',
        new_password:''
      },
      passFormRules:{
        username:[
          {required:'true', message:'请输入登录账号', trigger:'blur'}
        ],
        old_password:[
          {required:'true', message:'请输入旧密码', trigger:'blur'},
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
        ],
        new_password:[
          {required:'true', message:'请输入新密码', trigger:'blur'},
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
        ],
        renew_password:[
          {required:'true', message:'重新输入新密码', trigger:'blur'},
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
        ]
      } 
    }
  },
  created(){
    this.getInfo()
  },
  methods:{
    async getInfo(){
      const {data: res} = await this.$http.get('password')
      if(res.code !== 200){
        this.$message.error('获取失败')
      }
      console.log(res)
      this.passForm = res.data
    },
    async onSubmit(){
      this.$refs.passFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error("请填写相关信息");
        }
        
        if(this.passForm.new_password !== this.passForm.renew_password){
          return this.$message.error('确认密码不正确、请检查')
        }
        const {data: res} = await this.$http.put('password',this.passForm)
        if(res.code !== 200){
          return this.$message.error(res.msg)
        }
        this.$message.success('修改成功')
      });
    }
  }
}
</script>
