<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <!-- <img src="../assets/logo.png" alt=""> -->
        <span>Mastan 电影管理后台</span>
      </div>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <!-- 页面主题区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="toogleColapse ? '64px' : '200px'">
        <div class="toggle" @click="toggleCollapse">
          <i
            :class="toggleClass"
          ></i>
        </div>
        <el-menu
          background-color="#303133"
          text-color="#fff"
          active-text-color="#ffd04b"
          :unique-opened="true"
          :collapse="toogleColapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
          <el-submenu
            :index="item.id + ''"
            v-for="item in menuList"
            :key="item.id"
          >
            <template slot="title">
              <i :class="iconsObj[item.id]"></i>
              <span>{{ item.authName }}</span>
            </template>
            <el-menu-item
              :index="'/' + subItem.path"
              v-for="subItem in item.children"
              :key="subItem.id"
              @click="saveNavState('/' + subItem.path)"
            >
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>{{ subItem.authName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 侧边内容主题 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  created() {
    this.activePath = window.sessionStorage.getItem("activePath");
  },
  data() {
    return {
      // 左侧菜单数据
      menuList: [
        {
          id: 100,
          authName: "控制台",
          path: null,
          children: [
            {
              id: 101,
              authName: "数据统计",
              path: "home",
              children: [],
            },
          ],
        },
        {
          id: 200,
          authName: "电台",
          path: null,
          children: [
            {
              id: 202,
              authName: "电台管理",
              path: "fm/list",
              children: [],
            },{
              id: 201,
              authName: "电台分类",
              path: "fm/cate",
              children: [],
            }
          ],
        },
        {
          id: 300,
          authName: "电影",
          path: null,
          children: [
            {
              id: 302,
              authName: "电影管理",
              path: "movie",
              children: [],
            },{
              id: 301,
              authName: "电影分类",
              path: "movie/cate",
              children: [],
            }
          ],
        },
        {
          id: 400,
          authName: "用户",
          path: null,
          children: [
            {
              id: 401,
              authName: "用户管理",
              path: "users",
              children: [],
            },
          ],
        },
        {
          id: 800,
          authName: "评论",
          path: null,
          children: [
            {
              id: 401,
              authName: "评论列表",
              path: "commit",
              children: [],
            },
          ],
        },
        {
          id: 500,
          authName: "小程序",
          path: null,
          children: [
            {
              id: 501,
              authName: "幻灯片",
              path: "wechat/banner",
              children: [],
            },
            {
              id: 502,
              authName: "菜单管理",
              path: "wechat/menus",
              children: [],
            },
            {
              id: 503,
              authName: "参数配置",
              path: "wechat/param",
              children: [],
            },
            {
              id: 504,
              authName: "会员配置",
              path: "wechat/vip",
              children: [],
            },
            {
              id: 505,
              authName: "关于小程序",
              path: "wechat/about",
              children: [],
            },
          ],
        },
        {
          id: 600,
          authName: "订单",
          path: null,
          children: [
            {
              id: 601,
              authName: "付款记录",
              path: "order",
              children: [],
            },
          ],
        },
        {
          id:700,
          authName: "设置",
          path: null,
          children: [
            {
              id:701,
              authName: "密码安全",
              path: "sys/password",
              children: [],
            },
            // {
            //   id:702,
            //   authName: "系统配置",
            //   path: "sys",
            //   children: [],
            // },
          ],
        },
      ],
      iconsObj: {
        100: "iconfont icon-menu_kongzhitai",
        200: "iconfont icon-radio",
        300: "iconfont icon-movie",
        400: "iconfont icon-user",
        500: "iconfont icon-xiaochengxu",
        600: "iconfont icon-icon",
        700: "iconfont icon-shezhi",
        800: "iconfont icon-pinglun",
      },
      toogleColapse: false,
      toggleClass: 'iconfont icon-collapseleft',
      // 被激活的侧边栏连接
      activePath: "",
    };
  },
  methods: {
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },

    toggleCollapse() {
      this.toogleColapse = !this.toogleColapse;
      if (this.toogleColapse) {
        this.toggleClass = "iconfont icon-collapseright";
      } else {
        this.toggleClass = "iconfont icon-collapseleft";
      }
    },
    // 保存连接的激活状况
    saveNavState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  },
};
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}

.el-header {
  background-color: #222222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  color: #fff;

  div {
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
    }

    img {
      height: 44px;
      box-sizing: border-box;
      border-radius: 5px;
      margin-left: 15px;
    }
  }
}

.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #303133;

  .el-menu {
    border-right: none;
    color: #eee;
  }
}

.el-main {
  background-color: #eaedf1;
  width: 100%;
}

.iconfont {
  margin-right: 10px;
}

.toggle {
  background-color: #333;
  color: #909399;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
